<template>
  <div class="d-flex flex-column">
    <b-list-group v-if="filteredPosList && filteredPosList.length > 0">
      <b-list-group-item
        v-on:click.stop="isOnlyVisible ? () => {} : onPosClicked(pos)"
        :style="
          `border: 1px solid #aaa; ${isOnlyVisible ? '' : 'cursor: pointer'}`
        "
        :class="`p-1 mt-2 route_pos_list_item ${pos.status.statusValue}`"
        v-for="(pos, key) in filteredPosList"
        v-bind:key="`pos_list_item_${key}`"
      >
        <div class="d-flex justify-content-between align-center">
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <div class="d-flex flex-column w-full">
                <div class="d-flex align-center">
                  <b-badge variant="secondary" class="mr-2">
                    {{ routePosItemIdLabel(pos) }}
                  </b-badge>

                  <b-badge variant="primary" class="mr-2">
                    <b-icon
                      class="mx-1"
                      v-bind:icon="
                        visitTypeIcons[
                          pos.changedVisitType
                            ? pos.changedVisitType
                            : pos.visitType
                        ]
                          ? visitTypeIcons[
                              pos.changedVisitType
                                ? pos.changedVisitType
                                : pos.visitType
                            ]
                          : ''
                      "
                    ></b-icon>
                    {{
                      (pos.changedVisitType
                        ? visitTypes[pos.changedVisitType]
                        : pos.visitType && visitTypes[pos.visitType]) || ""
                    }}
                  </b-badge>

                  <sessionTagLabels
                    :postaglabels="pos.po.postaglabels"
                  ></sessionTagLabels>

                  <span
                    v-if="pos.status.isScheduled && pos.status.scheduleDateTime"
                  >
                    <b-icon icon="clock"></b-icon>
                    {{ `${pos.status.originalScheduleDateTime} h` }}
                  </span>
                </div>

                <div class="d-flex align-center">
                  <div
                    v-if="pos.isAdditional === true"
                    class="d-flex flex-column mr-3"
                  >
                    <v-btn
                      class="myroute_delete_button"
                      v-if="!isOnlyVisible"
                      v-on:click.stop="
                        onClickRemoveAdditionalItem(pos.additionalIds)
                      "
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex flex-column mt-2">
                    <div>
                      <b-badge
                        v-if="pos.po && !pos.po.isNotRequested"
                        v-bind:key="`new_pos_badge_${key}`"
                        variant="success"
                        class="mr-2"
                        >{{ `${$t("newitem")} POS` }}
                      </b-badge>
                      <span class="pos_name_label">
                        <b>{{ (pos.po.chain && pos.po.chain.name) || "" }}</b>
                      </span>
                      {{
                        pos.po.chain && pos.po.chain.name && pos.po.name
                          ? ","
                          : ""
                      }}
                      <span class="pos_name_label">
                        <b>{{ pos.po.name }}</b>
                      </span>
                    </div>
                    <div>
                      <span class="pos_detail_label">{{ pos.po.town }}</span>
                      ,
                      <span class="pos_detail_label">{{
                        pos.po.postalCode
                      }}</span>
                      ,
                      <span class="pos_detail_label">{{ pos.po.address }}</span>
                    </div>
                  </div>
                </div>

                <div class="d-flex align-center mt-2" style="flex-wrap: wrap;">
                  <template v-for="(brandItem, brandKey) in pos.brand_list">
                    <div
                      v-bind:key="`brandimage_${brandKey}`"
                      class="d-flex mr-2"
                    >
                      <img
                        style="border: 1px solid #aaa;"
                        :src="
                          getThumbnailUri(
                            brandItem.static
                          )
                        "
                        :alt="brandItem.name"
                        height="30"
                        class="mr-2"
                      />
                      <div class="text-sm py-2">
                        <b-icon class="mx-1" icon="calendar"></b-icon>
                        {{ labelOfFrequencyAndLastVisitDay(brandItem) }}
                      </div>
                    </div>
                  </template>
                  <template
                    v-if="pos?.pos_contacts && pos?.pos_contacts.length > 0"
                  >
                    <div class="d-flex align-items-center trencadis-title">
                      <span class="text-bold">TELF : </span>
                      <template
                        v-for="(contactItem, contactKey) in pos.pos_contacts"
                      >
                        <span :key="`contacts_${contactKey}`" class="my-2 ">
                          {{ contactItem.phone }}
                        </span>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              lg="4"
              class="d-flex"
              v-if="getSumPSPointsOfBrandItem(pos.brand_list) > 0"
            >
              <div class="d-flex flex-column w-full">
                <table
                  v-bind:key="key"
                  style="font-size: 13px;"
                  class="text-center w-full table-bordered"
                >
                  <colgroup>
                    <col width="" />
                    <col width="60px" />
                    <col width="60px" />
                    <col width="60px" />
                  </colgroup>
                  <tr>
                    <th></th>
                    <th>Obj</th>
                    <th>Real</th>
                    <th>vs ult</th>
                  </tr>
                  <template v-for="(brandItem, brandKey) in pos.brand_list">
                    <tr :key="`brand_obj_real_tr_${brandKey}`">
                      <td class="text-right">
                        <b style="white-space: nowrap;">{{ brandItem.name }}</b>
                      </td>
                      <td>{{ brandItem.objValue }}</td>
                      <td>{{ brandItem.posBrandPSLastVisit }}</td>
                      <td>{{ brandItem.diff_current_last_ps_label }}</td>
                    </tr>
                  </template>
                </table>
              </div>
            </v-col>
          </v-row>
        </div>
      </b-list-group-item>
    </b-list-group>
    <b-alert v-else show variant="warning">
      no hay puntos de ventas con estos filtros para hoy
    </b-alert>
  </div>
</template>

<script>
import { ellipsis } from "@/utils";
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import _ from "lodash";
import moment from "moment";
import sessionTagLabels from "@/components/sessionTagLabels";

export default {
  name: "routePosList",
  props: [
    "filteredPosList",
    "visitTypes",
    "isOnlyVisible",
    "onPosClicked",
    "onClickRemoveAdditionalItem"
  ],
  components: {
    sessionTagLabels
  },
  data() {
    return {
      visitTypeIcons: {
        PRESENT: "geo-alt-fill",
        PHONE: "telephone",
        AGENDA: "calendar",
        SCHEDULED: "calendar"
      }
    };
  },

  computed: {},
  methods: {
    getSumPSPointsOfBrandItem(brand_list) {
      let ret = 0;
      if (brand_list && brand_list.length > 0) {
        let arr_points = brand_list
          .map(el => parseFloat(el.posBrandPSLastVisit))
          .map(el => (isNaN(el) ? 0 : el));
        ret = _.sum(arr_points);
      }
      return ret;
    },
    labelOfFrequencyAndLastVisitDay(brandItem) {
      let labels = [];
      if (!_.isEmpty(brandItem.frequency_label))
        labels.push(brandItem.frequency_label);
      if (!_.isEmpty(brandItem.lastVisitDay))
        labels.push(brandItem.lastVisitDay);
      return `${labels.join(" - ")}`;
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    routePosItemIdLabel(posItem) {
      let labelItems = [];
      if (posItem?.po) {
        labelItems.push(posItem.posId);
        let idPosBrandList = [];
        idPosBrandList = this.getIdPosBrandList(posItem);
        idPosBrandList = idPosBrandList.map(el => el.idPosBrand);
        if (idPosBrandList.length > 0) {
          labelItems.push(idPosBrandList.join(", "));
        }
      }
      return labelItems.join(", ");
    },
    async init() {}
  },

  async mounted() {}
};
</script>

<style>
.stats-th-title {
  width: 120px;
}

.stats-th {
  width: 80px;
}

.myroute_delete_button {
  padding: 7px !important;
  border-radius: 50%;
  background: #d94645 !important;
  color: #fff !important;
  min-width: auto !important;
}

.pos_name_label {
  font-size: 16px;
  text-transform: uppercase;
}
.pos_detail_label {
  font-size: 13px;
  color: #777;
  text-transform: lowercase;
}
.NEGATIVE_INCOMPLETE .pos_detail_label {
  color: #000;
}
</style>
