var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.filteredPosList && _vm.filteredPosList.length > 0)?_c('b-list-group',_vm._l((_vm.filteredPosList),function(pos,key){return _c('b-list-group-item',{key:`pos_list_item_${key}`,class:`p-1 mt-2 route_pos_list_item ${pos.status.statusValue}`,style:(`border: 1px solid #aaa; ${_vm.isOnlyVisible ? '' : 'cursor: pointer'}`),on:{"click":function($event){$event.stopPropagation();_vm.isOnlyVisible ? () => {} : _vm.onPosClicked(pos)}}},[_c('div',{staticClass:"d-flex justify-content-between align-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"8"}},[_c('div',{staticClass:"d-flex flex-column w-full"},[_c('div',{staticClass:"d-flex align-center"},[_c('b-badge',{staticClass:"mr-2",attrs:{"variant":"secondary"}},[_vm._v(" "+_vm._s(_vm.routePosItemIdLabel(pos))+" ")]),_c('b-badge',{staticClass:"mr-2",attrs:{"variant":"primary"}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":_vm.visitTypeIcons[
                        pos.changedVisitType
                          ? pos.changedVisitType
                          : pos.visitType
                      ]
                        ? _vm.visitTypeIcons[
                            pos.changedVisitType
                              ? pos.changedVisitType
                              : pos.visitType
                          ]
                        : ''}}),_vm._v(" "+_vm._s((pos.changedVisitType ? _vm.visitTypes[pos.changedVisitType] : pos.visitType && _vm.visitTypes[pos.visitType]) || "")+" ")],1),_c('sessionTagLabels',{attrs:{"postaglabels":pos.po.postaglabels}}),(pos.status.isScheduled && pos.status.scheduleDateTime)?_c('span',[_c('b-icon',{attrs:{"icon":"clock"}}),_vm._v(" "+_vm._s(`${pos.status.originalScheduleDateTime} h`)+" ")],1):_vm._e()],1),_c('div',{staticClass:"d-flex align-center"},[(pos.isAdditional === true)?_c('div',{staticClass:"d-flex flex-column mr-3"},[(!_vm.isOnlyVisible)?_c('v-btn',{staticClass:"myroute_delete_button",on:{"click":function($event){$event.stopPropagation();return _vm.onClickRemoveAdditionalItem(pos.additionalIds)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column mt-2"},[_c('div',[(pos.po && !pos.po.isNotRequested)?_c('b-badge',{key:`new_pos_badge_${key}`,staticClass:"mr-2",attrs:{"variant":"success"}},[_vm._v(_vm._s(`${_vm.$t("newitem")} POS`)+" ")]):_vm._e(),_c('span',{staticClass:"pos_name_label"},[_c('b',[_vm._v(_vm._s((pos.po.chain && pos.po.chain.name) || ""))])]),_vm._v(" "+_vm._s(pos.po.chain && pos.po.chain.name && pos.po.name ? "," : "")+" "),_c('span',{staticClass:"pos_name_label"},[_c('b',[_vm._v(_vm._s(pos.po.name))])])],1),_c('div',[_c('span',{staticClass:"pos_detail_label"},[_vm._v(_vm._s(pos.po.town))]),_vm._v(" , "),_c('span',{staticClass:"pos_detail_label"},[_vm._v(_vm._s(pos.po.postalCode))]),_vm._v(" , "),_c('span',{staticClass:"pos_detail_label"},[_vm._v(_vm._s(pos.po.address))])])])]),_c('div',{staticClass:"d-flex align-center mt-2",staticStyle:{"flex-wrap":"wrap"}},[_vm._l((pos.brand_list),function(brandItem,brandKey){return [_c('div',{key:`brandimage_${brandKey}`,staticClass:"d-flex mr-2"},[_c('img',{staticClass:"mr-2",staticStyle:{"border":"1px solid #aaa"},attrs:{"src":_vm.getThumbnailUri(
                          brandItem.static
                        ),"alt":brandItem.name,"height":"30"}}),_c('div',{staticClass:"text-sm py-2"},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"calendar"}}),_vm._v(" "+_vm._s(_vm.labelOfFrequencyAndLastVisitDay(brandItem))+" ")],1)])]}),(pos?.pos_contacts && pos?.pos_contacts.length > 0)?[_c('div',{staticClass:"d-flex align-items-center trencadis-title"},[_c('span',{staticClass:"text-bold"},[_vm._v("TELF : ")]),_vm._l((pos.pos_contacts),function(contactItem,contactKey){return [_c('span',{key:`contacts_${contactKey}`,staticClass:"my-2"},[_vm._v(" "+_vm._s(contactItem.phone)+" ")])]})],2)]:_vm._e()],2)])]),(_vm.getSumPSPointsOfBrandItem(pos.brand_list) > 0)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"12","md":"4","lg":"4"}},[_c('div',{staticClass:"d-flex flex-column w-full"},[_c('table',{key:key,staticClass:"text-center w-full table-bordered",staticStyle:{"font-size":"13px"}},[_c('colgroup',[_c('col',{attrs:{"width":""}}),_c('col',{attrs:{"width":"60px"}}),_c('col',{attrs:{"width":"60px"}}),_c('col',{attrs:{"width":"60px"}})]),_c('tr',[_c('th'),_c('th',[_vm._v("Obj")]),_c('th',[_vm._v("Real")]),_c('th',[_vm._v("vs ult")])]),_vm._l((pos.brand_list),function(brandItem,brandKey){return [_c('tr',{key:`brand_obj_real_tr_${brandKey}`},[_c('td',{staticClass:"text-right"},[_c('b',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(brandItem.name))])]),_c('td',[_vm._v(_vm._s(brandItem.objValue))]),_c('td',[_vm._v(_vm._s(brandItem.posBrandPSLastVisit))]),_c('td',[_vm._v(_vm._s(brandItem.diff_current_last_ps_label))])])]})],2)])]):_vm._e()],1)],1)])}),1):_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_vm._v(" no hay puntos de ventas con estos filtros para hoy ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }